<template>
  <div class="background">
    <div class="login">
      <van-row class="page-top-content">
        <!--        <van-col span="8" offset="4">-->
        <!--          <div class="fontSize" @click="belongPassword">-->
        <!--            密码绑定-->
        <!--          </div>-->
        <!--          <div v-if="binding==1" class="xhx">-->
        <!--          </div>-->
        <!--        </van-col>-->
        <van-col span="24">
          <div class="fontSize">
            手机号绑定
          </div>
        </van-col>
      </van-row>

      <div class='panel-col-center item-center qh'>
        <p class="input_title">手机号</p>
        <div style=" display: flex  ; align-items: center ;  justify-content: center ;">
          <van-field class='input' v-model="dataForm.mobile" placeholder="请输入手机号" maxlength='12'
                     :center="true" clearable/>
        </div>
        <p class="input_title">验证码</p>
        <div
            style=" position:relative;display: flex  ; align-items: center ;  justify-content: center ;">
          <van-field class='input' v-model="dataForm.code" placeholder="请输入验证码" maxlength='11'
                     :center="true" clearable/>
          <div :disabled='btnNotSend' @click='verifyMobile'
               style="position: absolute;color:#3890F9; font-size: 0.44rem; right: 15%;z-index: 9;">
            {{ btnSendText }}
          </div>
        </div>
        <div class='padding-md' style="margin-top:0.95rem;">
          <van-button class='submit' type='primary' size="large" @click="verifyMobileSure">绑定账号</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getSSOMessage} from '../../utils'
import {CheckPassWord} from "../../utils/validate";
import {Toast} from "vant";
import Vue from "vue";
import http from '@/utils/httpRequest'
import httpRequestApp from '@/utils/httpRequest-app'
import {blockUser, setSDK, setUser, startUser} from "@/utils/aplus_cloud";
import rsaUtils from "@/utils/rsaUtils";
import {twoDomainLogin} from '@/utils/common'

var that
export default {
  data () {
    return {
      logo: require('@/assets/img/logo.png'),
      btnSendText: '获取验证码',
      btnNotSend: false,
      dataForm: {
        orgValue: '',
        mobile: '',
        code: '',
        authCode: ''
      },
      dataRule: {
        mobile: [
          {required: true, message: '手机号不能为空', trigger: 'blur'}
        ],
        code: [
          {required: true, message: '验证码不能为空', trigger: 'blur'}
        ]
      },
      orgList: [],
      showOrgList: false
    }
  },
  created () {
    that = this
    let query = this.$route.query
    if (query.authCode) {
      this.dataForm.authCode = query.authCode
      this.getOpenId()
    }
  },
  watch: {
    second: {
      handler (val) {
        if (val === 0) {
          clearInterval(this.timer)
          this.timer = null
          this.reSend = true
        }
      }
    }
  },
  methods: {
    getOpenId () {
      this.$http({
        url: 'https://jjt.sxyc.gov.cn/api/wxapp/sxcitybrain/getOpenId',
        // url: this.$http.adornUrl('/wxapp/sxcitybrain/getOpenId'),
        method: 'get',
        params: this.$http.adornParams({authCode: this.dataForm.authCode})
      }).then(({data}) => {
        this.checkLogin(data)
      })
    },
    checkLogin (data) {
      if (data.appEffectiveDateEntity) {
        Vue.prototype.$globalData.systemEffectiveDate = data.appEffectiveDateEntity
      }
      if (data.code == 0) {
        if(data.homePageVO.orgId === data.homePageVO.street) {
          Vue.prototype.$depth = 4
        }else {
          Vue.prototype.$depth = 5
        }
        Vue.prototype.$globalData.token = data.token
        Vue.prototype.$globalData.topToken = data.token
        Vue.prototype.$globalData.ddUser = data.ddUser
        if (data.homePageVO != null) {
          Vue.prototype.$globalData.topUserId = data.homePageVO.userId
          Vue.prototype.$isOld = data.homePageVO.isOld
          if (data.homePageVO.domain) {
            Vue.prototype.$globalData.domain = decodeURIComponent(data.homePageVO.domain) + '/api'
            Vue.prototype.$globalData.domain2 = decodeURIComponent(data.homePageVO.domain)
          }
          http.changeUrl(data.homePageVO.domain, true);
          httpRequestApp.changeUrl(data.homePageVO.domain, true)
          that.$globalData.userInfo = data.homePageVO
          Vue.prototype.$orgId = data.homePageVO.orgId
        }
        // wx.setStorageSync('uhtUserInfo', data.rows);
        for (let i in data.homePageVO) {
          let value = data.homePageVO[i]
          if (!value) {
            value = ""
          }
        }
        if (this.path) {
          if(data.homePageVO.orgId === data.homePageVO.street) {
            that.$router.push({path: '/street-home', query: {dd_nav_translucent: true}})
          }else {
            that.$router.push({path: '/home', query: {dd_nav_translucent: true}})
          }
        } else {
          if(data.homePageVO.orgId === data.homePageVO.street) {
            that.$router.push({path: '/street-home'})
          }else {
            that.$router.push({path: '/home'})
          }
        }
      } else {
        if (data.code == 501) {
          if (data.orgList && data.orgList.length) {
            this.dataForm.orgValue = data.orgList[data.orgList.length - 1]
          } else {
            this.showOrgList = true
            this.getOrgList()
          }
        } else {
          Toast.fail(data.msg)
        }
      }
    },
    verifyMobileSure () {
      this.$http({
        url: `https://jjt.sxyc.gov.cn/api/wxapp/sxcitybrain/mobileLogin`,
        // url: this.$http.adornUrl(`/wxapp/sxcitybrain/mobileLogin`),
        method: 'post',
        data: this.$http.adornData({
          mobile: this.dataForm.mobile,
          userCode: this.dataForm.code,
          authCode: this.dataForm.authCode,
          orgId: this.dataForm.orgValue
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.checkLogin(data)
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    getOrgList () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/org/getUserOrg`),
        method: 'post',
        params: this.$http.adornParams({
          'depth': 5
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.orgList = data.userTreeOrg[0].children
          this.setDisabled(this.orgList)
        }
      })
    },
    setDisabled (list) {
      list.map(item => {
        if (item.depth < 3) {
          item.disabled = true
        }
        if (item.children && item.children.length) {
          this.setDisabled(item.children)
        }
      })
    },
    verifyUser(success) {
      if (!that.dataForm.mobile.trim()) {
        Toast.fail('请输入手机号！');
        return;
      }
      that.$http({
        url: that.$http.adornUrl(`/wxapp/user/mobileVerifyUser`),
        method: 'get',
        params: that.$http.adornParams({mobile: that.dataForm.mobile})
      }).then(({data}) => {
        if (data.code == 0) {
          success(true)
        } else {
          Toast.fail(data.msg, 1500);
          success(false, data.msg)
        }
      })
    },
    verifyMobile() {
      // that.verifyUser(function (success) {
      //   if (success) {
          Toast.loading({
            message: '发送中...',
            forbidClick: true,
          })
          that.$http({
            url: 'https://api.hzuht.com/general/verify/sendVerifyCode',
            method: 'post',
            params: that.$http.adornParams({
              token: that.code,
              mobile: that.dataForm.mobile,
              needExist: 1,
              force: true
            })
          }).then(({data}) => {
            that.btnNotSend = true
            Toast.clear()
            if (data.code != 0) {
              Toast.fail(data.msg);
            } else {
              that.countDown();
            }
          })
        // }

      // })

    },
    countDown: function () {
      let that = this;
      let countDownNum = 60;//获取倒计时初始值
      //如果将定时器设置在外面，那么用户就看不到countDownNum的数值动态变化，所以要把定时器存进data里面
      that.timer = setInterval(function () {//这里把setInterval赋值给变量名为timer的变量
        //每隔一秒countDownNum就减一，实现同步
        countDownNum--;
        //然后把countDownNum存进data，好让用户知道时间在倒计着
        that.btnSendText = countDownNum
        //在倒计时还未到0时，这中间可以做其他的事情，按项目需求来
        if (countDownNum == 0) {
          //这里特别要注意，计时器是始终一直在走的，如果你的时间为0，那么就要关掉定时器！不然相当耗性能
          //因为timer是存在data里面的，所以在关掉时，也要在data里取出后再关闭
          clearInterval(that.timer);
          //关闭定时器之后，可作其他处理codes go here
          that.btnNotSend = false

          that.btnSendText = "刷新"
        }
      }, 1000)
    },
    setSecond () {
      this.timer = window.setInterval(() => {
        setTimeout(this.second--, 0)
      }, 1000)
    }
  }
}
</script>
<style lang="scss" scoped>
.background {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  text-align: center;
  background-image: url('~@/assets/img/login_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 70px;
}

.top_logo {
  text-align: left;
}

.logo {
  margin-top: 200px;
  width: 195px;
}

.slogen {
  font-size: 30px;
  color: #333;
}

.input_title {
  font-size: 28px;
  color: #2E2E48;
  font-weight: bold;
  padding-left: 35px;
  text-align: left;
  margin: 20px 0 0;
}

.xhx {
  border-bottom: 6px solid #034cb9;
  width: 40px;
  margin: 15px auto 0 auto;
  border-radius: 7px;
}

.login {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 10px 80px 0px rgba(106, 162, 255, 0.3);
  padding: 40px 0;
  border-radius: 10px;
}

.fontSize {
  font-size: 32px;
}

.input {
  width: 70vw;
  height: 80px;
  line-height: 60px;
  text-align: center;
  font-size: 30px;
  color: #000;
  border-radius: 10px;
  //background: #f1f2f7;
  border-bottom: 2px solid #F3F5FA;
}

::v-deep .van-cell {
  padding: 0;
}

.panel-col-center {
  float: none;
  justify-content: center;
  flex-flow: column;
}

::v-deep .van-field__left-icon .van-icon {
  display: block;
  font-size: 45px !important;
  line-height: inherit;
}

::v-deep .van-checkbox {
  justify-content: center;
}

.submit {
  width: 70vw;
  background-color: #0471FD !important;
  font-size: 30px;
  color: #fff;
  height: 80px;
  line-height: 40px;
  border-radius: 40px;
  border-color: #4f85ff
}

.qh {
  border-radius: 5px;
  background-color: #fff;
  margin: 20px 10px;
  position: relative;
}

.yd {
  font-size: 24px;
  margin-top: 33px;
}

.ydbty {
  transform: scale(0.8, 0.8);
  vertical-align: middle;
  margin-bottom: 6px;
}

.sm {
  font-size: 22px;
  margin: 45px 0;
  text-align: left;
  color: #8a979e;
}
</style>
